.all-location-screen {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  .location-card {
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .location-card:hover {
    transform: scale(1.05);
  }
  
  .location-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .location-details {
    padding: 15px;
  }
  
  .location-details h6 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .my-1 {
    margin: 8px 0;
  }
  