/* Nav dropdownLocation item */
.dropbtnLocation {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.dropdownLocation {
  position: relative;
  display: inline-block;
}

.dropdownLocation-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border: none;
  border-bottom: none;
  border-radius: 10px;
  min-width: 300%;
  left: 50%;
  transform: translateX(-50%);
}

.dropdownLocation-content::before {
  content: '';
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  border: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #fff transparent;
}

.dropdownLocation-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.dropdownLocation-content a:hover {
  background-color: #fff;
}

.dropdownLocation:hover .dropdownLocation-content {
  display: block;
}

.dropdownLocation:hover .dropbtnLocation {
  background-color: #FFC87D;
  color: #007080;
}

.dropdownLocation-content .container,
.dropdownLocation-content .row {
  padding: 0;
  margin: 0;
}

.custom-rounded {
  border-radius: 10px;
}

/* Nav dropdownCurriculum item */
.dropbtnCurriculum {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.dropdownCurriculum {
  position: relative;
  display: inline-block;
}

.dropdownCurriculum-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border: none;
  border-bottom: none;
  border-radius: 10px;
  min-width: 400%;
  left: 50%;
  transform: translateX(-50%);
}

.dropdownCurriculum-content::before {
  content: '';
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  border: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #fff transparent;
}

.dropdownCurriculum-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.dropdownCurriculum-content a:hover {
  background-color: #fff;
}

.dropdownCurriculum:hover .dropdownCurriculum-content {
  display: block;
}

.dropdownCurriculum:hover .dropbtnCurriculum {
  background-color: #FFC87D;
  color: #007080;
}

.dropdownCurriculum-content .container,
.dropdownCurriculum-content .row {
  padding: 0;
  margin: 0;
}

/* Nav dropdownShop item */
.dropbtnShop {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.dropdownShop {
  position: relative;
  display: inline-block;
}

.dropdownShop-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: none;
  border-bottom: none;
  border-radius: 10px;
  // min-width: 600px;
  left: auto;
  right: 0;
  // transform: translateX(calc(50% + 150px)); 
}


.dropdownShop-content::before {
  content: '';
  position: absolute;
  top: -8px;
  left: 66px; /* Adjust the value as needed */
  border: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #fff transparent;
  transform: translateX(50%);
}


.dropdownShop-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.dropdownShop-content a:hover {
  background-color: #fff;
}

.dropdownShop:hover .dropdownShop-content {
  display: block;
}

.dropdownShop:hover .dropbtnShop {
  background-color: #FFC87D;
  color: #007080;
}

.dropdownShop-content .container,
.dropdownShop-content .row {
  padding: 0;
  margin: 0;
}

/* Nav dropdownAccount item */
.dropbtnAccount {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.dropdownAccount {
  position: relative;
  display: inline-block;
}

.dropdownAccount-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px;
  border: none;
  border-bottom: none;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.dropdownAccount-content::before {
  content: '';
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  border: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #fff transparent;
}

.dropdownAccount-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.dropdownAccount-content a:hover {
  background-color: #fff;
}

.dropdownAccount:hover .dropdownAccount-content {
  display: block;
}

.dropdownAccount:hover .dropbtnAccount {
  background-color: #FFC87D;
  color: #007080;
}

.scrolled .custom-link {
  color: #007080;
  font-size: 18px;
  line-height: 36px;
}

.unscrolled .custom-link {
  color: #ffffff;
  font-size: 18px;
  line-height: 36px;
}

@media (max-width: 767px) {
  .custom-link {
    color: #fff !important; 
  }
}

/* Add shadow */
.dropdownLocation-content,
.dropdownCurriculum-content,
.dropdownShop-content,
.dropdownAccount-content {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  padding: 10px;
}

.dropdownLocation-content a,
.dropdownCurriculum-content a,
.dropdownShop-content a,
.dropdownAccount-content a {
  padding: 8px 12px;
  font-size: 14px;
}

.dropbtnLocation,
.dropbtnCurriculum,
.dropbtnShop,
.dropbtnAccount {
  padding: 12px;
  font-size: 14px;
}

@media (max-width: 767px) {
  .card img {
    display: none;
  }
}

@media (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
}

.image-carousel {
  position: relative;
  height: 100%;
  width: 100%;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s;
}

.carousel-image.active {
  opacity: 1;
  animation: fade-in 1s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .custom-collapse {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .hide-carousel {
    display: none;
  }
}

.scroll-background {
  background-color: #007080 !important;
}

.custom-collapse .navbar-toggler-icon {
  color: white !important;
}

@media (max-width: 768px) {
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
    border: 2px solid #fff !important;
  }
}

.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 29px !important;
  color: #fff  !important;
}

.close-button:hover {
  color: #ccc;
}

.navbar-image {
  position: absolute;
  top: 10px;
  left: 42%;
  bottom: 17% !important;
  z-index: 9999;
  float: left !important;
}

.d-none {
  display: none !important;
}
