body, html {
  height: 100%;
  font-family: 'Nunito';
}

#header {
  // margin: 0 !important;
  // padding-top: 0px !important;
  clear: both;
}

main {
  min-height: 80vh;
}



h1, h2, h3, h4, h5, h6 {
  color: #007080;
  font-weight: 700;
}



h1 {
  font-size: 1.8rem;
  padding: 1rem 0; 
}

h2 {
  font-size: 1.4rem;
  /* padding: 0.5rem 0; */
}




/* start Phone input styling*/

  .react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #CDF8EE !important;
    border: none !important;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 100% !important;
    outline: none;
    min-height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;

}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #CDF8EE !important;
  border-radius: 3px 0 0 0;
}

.react-tel-input .flag-dropdown {
  border: none !important;
}


.react-tel-input .flag-dropdown {
    background: #CDF8EE !important;
    border: none !important;
}


/* end Phone input styling*/


/*shop all button*/
.shop-all {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 178px !important;
  height: 40px !important;
  float: right !important;
  background: #CDF8EE;
  border: 1px solid #312636;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 30px !important;
}



.add-to-cart-card {
  padding: 16px;
  gap: 26px;
  width: 100%;
  background: #FBFAF5 !important;
  border: none !important;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

@media (max-width: 768px) {
  .add-to-cart-card {
    padding: 0px;
    gap: 0px;
  }
}

.add-to-cart-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.clickable-card-content {
  position: relative;
}

.discount-badge {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #FFC87D;
  border-radius: 50%;
  font-size: 1.2rem;
  padding: 0.5rem;
  color: #000;
  transition: transform 0.2s ease-in-out;
}

.add-to-cart-card:hover .discount-badge {
  transform: scale(1.1);
}

/*OTP input styling*/
.fa-rotate {
  animation: fa-spin 2s infinite linear;
}

/*Navbar styling classes*/
.banner-nav {
  background-color: #007080;
  color: #fff;
  padding-bottom: 10px;
}

.header-banner {
  background-color: #f5f5f5;
  color: #333;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  height: 40px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 0 0; /* add top padding here */
}


.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* or "contain" */
}

.carousel-bg {
  background-color: #fff;
}



.cart-footer-product {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.checkout-button {
  background-color: #4CAF50;
  color: white;
  padding: 16px 32px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}


.custome-offcanvas {
  width: 40%;
}

.custom-offcanvas {
  width: 35% !important;
}


.custom-product-offcanvas {
  width: 60% !important;
}

@media (max-width: 768px) {
  .custom-product-offcanvas {
    width: 100%;
  }
  .custom-offcanvas {
    width: 100%;
  }
  .custome-offcanvas {
    width: 100%;
  }
  
}


.link {
  text-decoration: none !important;
}

.my-row:hover {
  background-color: #f2f2f2;
}



.hero-image {
  height: 20vh; /* set the height to 70% of the viewport height */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 50px; /* add padding of 50px */
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.hero-text button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 10px 25px;
  color: black;
  background-color: #ddd;
  text-align: center;
  cursor: pointer;
}

.hero-text button:hover {
  background-color: #007080;
  color: white;
}


.course-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-4px);
  }
}

.course-image-container {
  position: relative;
  overflow: hidden;

  &:hover .course-image-overlay {
    opacity: 0.7;
  }
}

.course-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
}

.course-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.course-subtitle {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 1rem;
}

.course-info {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.course-info-item {
  display: flex;
  align-items: center;
  color: #6c757d;
  font-size: 0.875rem;

  span {
    margin-left: 0.5rem;
  }
}

@media (min-width: 768px) {
  .course-card {
    height: 100%;
  }

  .course-info {
    margin-top: 2rem;
  }

  .course-info-item {
    font-size: 1rem;
  }
}

a.location-link {
  display: flex;
  align-items: center;
  color: #333;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}

a.location-link:hover {
  color: #6c757d;
}

a.location-link i {
  font-size: 2rem;
  margin-right: 1rem;
  color: #6c757d;
}


.custom-pagination .page-item.active .page-link {
  background-color: #25cff2 !important;
  border-color: #25cff2 !important;
}

.custom-pagination .page-link {
  color: #000 !important;
}

.custom-pagination .page-link:hover {
  background-color: #25cff2 !important;
  border-color: #000 !important;
}



.required-field:required {
  border-color: red !important;
}

.asterisk {
  color: red !important;
}



.link {
  text-decoration: none;
  color: #333;
}

.my-row {
  align-items: center;
}

.custom-heading {
  font-size: 1.3rem;
  font-weight: bold;
  color: #007080;
  margin-bottom: 0.5rem;
}

.message-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.message-container strong {
  font-weight: bold;
  color: #007080;
}

.message-container blockquote {
  font-style: italic;
  color: #007080;
  margin: 0;
}

h5 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007080;
  margin-bottom: 0.5rem;
}

.DeleteUpload {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
}

.DeleteUpload i {
  font-size: 1rem;
}


.breadcrumb-link {
  text-decoration: none !important;
  color: #007080;
}

.breadcrumb-styling{
  a {
    text-decoration: none !important;
    color: #007080;
  }
}

.breadcrumb-item-text {
  text-decoration: none !important;
  color: #007080;
}


.breadcrumb-item+.breadcrumb-item::before {
  color: #007080 !important;
}


/*Location card*/

.location-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  isolation: isolate;

  position: relative;
  background: #FBFAF5;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.location-card:hover {
  transform: translateY(-5px);
}

.location-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.location-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.location-details hr {
  width: 100%;
  height: 1px;
  background-color: #007080 !important;
  border: none;
  margin: 8px 0;
}

@media (max-width: 768px) {
  .location-card {
    margin-bottom: 1.5rem;
  }
}




.card {
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.avatar-md {
  height: 5rem;
  width: 5rem;
}

.fs-19 {
  font-size: 19px;
}

.primary-link {
  color: #314047;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

a {
  color: #02af74;
  text-decoration: none;
}

.bookmark-post .favorite-icon a, .job-box.bookmark-post .favorite-icon a {
  background-color: #da3746;
  color: #fff;
  border-color: danger;
}
.favorite-icon a {
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #eff0f2;
  border-radius: 6px;
  color: rgba(173,181,189,.55);
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}


.candidate-list-box .favorite-icon {
  position: absolute;
  right: 22px;
  top: 22px;
}
.fs-14 {
  font-size: 14px;
}
.bg-soft-secondary {
  background-color: rgba(116,120,141,.15)!important;
  color: #74788d!important;
}

.mt-1 {
  margin-top: 0.25rem!important;
}


.social-icons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.icon {
  color: black;
}

.contact-info {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 14px;
  line-height: 1.5;
}

.contact-label {
  font-weight: bold;
}


.custom-button {
  border-color: #007080 !important;
  color: #007080 !important;
}

.custom-button:hover {
  background-color: #fff !important;
  color: #fff !important;
}



.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  width: 3rem;
  height: 3rem;
}


.img-bg {
  background-color: #312636;
  min-width: 260px;
  min-height: 230px;
  border-radius: 50%;
  padding: 29px !important;
}



.price {
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
}

.price-value {
  font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
}


.title {
  font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1rem !important;
  }
}

.card-text {
  font-size: 0.9rem;

  @media (max-width: 768px) {
    font-size: 0.8rem !important; 
  }
}



.card-header, .card-title {
  text-transform: capitalize !important;
}


// .navbar-image {
//   position: absolute;
//   z-index: 9999;
//   top: 0;
//   left: 50%;
//   transform: translateX(-50%);
// }



@media (max-width: 768px) {
  .carousel-control-next-icon, .carousel-control-prev-icon {
    display: none !important;
  }

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  margin: auto;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
  // display: none;
}
}


.full-height-navbar {
  height: 100vh; /* 100% viewport height */
}



.mobile-view {
  width: 50%;
}

@media (min-width: 768px) {
  .mobile-view {
    width: 100%;
  }
}

.cart-canvas {
  width: 1500px; /* Adjust the width as needed */
}



  .img-bg-mobile {
    background-color: #312636;
    width: 158px;
    height: 138px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img-bg-mobile img {
    max-width: 100%;
    max-height: 100%;
  }

.link-color{
  color:#007080;
}

.link-padding {
  // padding: 3px;
}



/* CakesCategory.css */

// @media (max-width: 767px) {
//   .show-truncated {
//     line-height: 1.5;
//   }

//   .show-full {
//     line-height: 1.5;
//     white-space: pre-wrap;
//   }

//   .show-more {
//     color: #007080;
//     cursor: pointer;
//     font-weight: bold;
//   }

//   .show-more:hover {
//     text-decoration: underline;
//   }
// }



.how-it-started-container {
  text-align: center;
  // margin: 50px;
  // font-family: Arial, sans-serif;
  line-height: 1.6;
}

.how-it-started-container h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.how-it-started-container p {
  margin-bottom: 20px;
}

.how-it-started-container ul {
  margin-bottom: 20px;
  padding-left: 20px;
}

.how-it-started-container li {
  margin-bottom: 10px;
}


.page-content-wrapper {
  min-height: calc(100vh - 100px); /* Adjust the value (100px) based on your footer height */
  // padding-bottom: 100px; /* Adjust the value (100px) based on your footer height */
}

.custom-column {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.checkbox-group {
  overflow-y: auto;
}

.checkbox-item {
  margin-bottom: 10px;
}


.tag-group {
  overflow-y: auto;
}

.tag-list {
  padding: 0;
  margin-bottom: 0;
}

.tag-list .list-group-item {
  padding: 10px;
  border: none;
}

.tag-list .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tag-list .list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}



.not-found-template {
  margin: 20px;
  padding: 20px;
  background-color: #CDF8EE;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.not-found-template h4 {
  color: #007080;
  font-size: 24px;
  margin-bottom: 10px;
}

.not-found-template p {
  color: #007080;
  font-size: 16px;
}


/* ProductDetailsCanvas.css */

/* Styling for the product image */
// .product-image {
//   width: 100%;
//   max-height: 300px;
//   object-fit: cover;
//   border-radius: 5px;
// }

/* Styling for product labels */
.product-label {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Styling for the product details list */
.list-group {
  margin-top: 20px;
}

/* Styling for list items in the product details list */
.list-group-item {
  border: none;
  padding: 15px 0;
}

/* Styling for the weights list */
.list-group-item ul {
  list-style: none;
  padding-left: 0;
}

/* Styling for individual weight items */
.list-group-item li {
  margin-bottom: 5px;
}

/* Styling for promotion details */
.promotion-details {
  margin-top: 10px;
}

/* Styling for promotion dates and discount */
.promotion-details p {
  margin-bottom: 5px;
}


.order-details {
  font-size: 16px;
  line-height: 1.6;
  padding: 20px;
}

.order-details h5 {
  margin-bottom: 10px;
}

.order-details h6 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.order-details ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.order-details li {
  margin-bottom: 10px;
}

.item-name {
  font-weight: bold;
}

.item-price {
  color: #666;
}


.order-details {
  padding: 20px;
}

.order-items-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.item-card {
  width: 220px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.item-card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* SingleLocationScreen.css */

/* Add custom styles here to improve the appearance of the SingleLocationScreen component */
/* For example: */

.page-content-wrapper {
  padding: 20px;
}

.location-link {
  font-size: 18px;
}

.location-link:hover {
  color: #007bff;
}

.hover-bg:hover {
  background-color: #f8f9fa;
}

/* Add more styles as needed to achieve your desired UI and design */
.location-image-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.location-image-card img {
  border-radius: 10px;
  flex: 1;
  object-fit: cover; /* Ensures the image fills the entire card */
}

.location-details-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  background-color: #f8f8f8;
}

.location-details-card .location-link {
  font-size: 1.1rem;
}

.location-details-card .location-link:hover {
  color: #007bff;
}

.location-details-card .hover-bg:hover {
  background-color: #f0f0f0;
}

.location-details-card .flex-grow-1 {
  flex-grow: 1;
}

.order-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.buttons-container {
  display: flex;
}

.complete-payment-button {
  margin-right: 10px;
  float: right;
}

.see-order-details-button {
  float: right;
}


// @media (max-width: 767px) {
//   .order-item {
//     // display: flex;
//     // flex-direction: column;
//     align-items: center;
//   }

//   .item-image{
//     display: block;
//   }
  
// }


.product-card {
  width: 100%;
  max-width: 300px;
  margin: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
}

/* Style the product image */
.product-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 12px;
}

/* Style the product name */
.product-card h5 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

/* Style the price button container */
.price-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style the discount button */
.discount-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
}

.discount-button h5 {
  margin-bottom: 4px;
}

.discount-button s {
  color: #888;
  font-size: 14px;
}

/* Style the "Price not available" text */
.price-not-available {
  color: #888;
  font-size: 16px;
  margin: 8px 0;
}

/* Style the "Shop now" button */
.d-grid.gap-2 {
  margin-top: 16px;
}

.scaled-image {
  transition: transform 0.3s ease;
}

/* Scale the image when the navbar is scrolled and not at the top */
.scrolled .scaled-image {
  transform: scale(1); /* You can adjust the scale value as needed */
}

/* Additional styling for when the navbar is at the top */
.unscrolled .scaled-image {
  transform: scale(0.7);
}


/* Assuming you have a CSS file where you want to add the custom styles */
.custom-table {
  color: #007080 !important; /* Text color */
}

.custom-table th {
  background-color: #3CBBB1; /* Header background color */
  color: #007080 !important; /* Header text color */
}

.custom-table tbody tr:nth-child(odd) {
  background-color: #CDF8EE; /* Striped color for odd rows */
  color: #007080 !important; 
}

.custom-table tbody tr:nth-child(even) {
  background-color: #fff !important; /* Striped color for even rows (if needed) */
}

.custom-table tbody tr:hover {
  background-color: #E2F4F2; /* Hover background color */
}



.table-striped>tbody>tr:nth-of-type(odd)>* {
  color: #007080 !important; 
}


@media screen and (max-width: 768px) {
  .truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}



.small-image {
  max-width: 360px; /* Adjust the maximum width */
  max-height: 360px; /* Adjust the maximum height */
  object-fit: cover; /* Maintain aspect ratio and cover the space */
  display: block;
}

@media (max-width: 768px) {
  .small-image {
    display: block !important;
  }
}

.search-container {
  margin-bottom: 20px;
}

.search-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}