.floating-whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px; /* Updated to 'right' */
    z-index: 9999;
  }
  
  .whatsapp-icon {
    background-color: green;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .whatsapp-icon img {
    width: 40px;
    height: 40px;
  }
  
  .chat-box {
    display: none;
    position: absolute;
    bottom: 70px;
    right: 0;
    width: 300px;
    height: 200px;
    background-color: white;
    border: 1px solid gray;
    padding: 10px;
    z-index: 9999;
  }